import swal from '@sweetalert/with-react';
import React, {useState,useEffect} from 'react'
import Input from '../../../components/input';
import { adminLogin } from '../../../services/adminService';
import "./admin_login.css";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  useEffect(() => {
    sessionStorage.removeItem("admin-token");
})

  let navigate = useNavigate();
    const [user, setUser] = useState({
        name: "",
        password:""
    })

    const [isLoading, setIsLoading] = useState(false);
    const { name, password } = user;

    function handleInputChange({target}) {
        const userDetails = { ...user };
        userDetails[target.name] = target.value;
        setUser(userDetails);
    }

    function handleFormSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        adminLogin(user).then(res => {
            setIsLoading(false);
          swal("Successful", "Successfully logged in", "success")
          sessionStorage.setItem("admin-token", res.data)
          navigate("/admin/dashboard");
            console.log(res);
        }).catch(err => {
            setIsLoading(false);
            setUser({ name: "", password: "" });
            console.log(err);
             swal("Couldn't log you in",`${err.response.data}`, "error");
        })

        console.log(user);
    }
    return (
      <>
        <div className='admin-login-container'>
          <h1>Admin Login</h1>
          <form onSubmit={handleFormSubmit}>
                <Input
                  value={name}
                  type={"text"}
                  onChange={handleInputChange}
                  name="name"
                  placeholder={"Enter name"}
                />
                <Input
                  value={password}
                  type={"password"}
                  onChange={handleInputChange}
                  name="password"
                  placeholder={"Enter Password"}
                />
                <br />
                <button type="submit">{isLoading?"Please wait...":"Login"}</button>
          </form>
        </div>
      </>
    );
}
 
export default AdminLogin;