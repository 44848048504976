import http from "./httpService";
import api from "./config";

const apiEndpoint = `${api}/admin`;

export async function adminLogin(user){
 return await http.post(`${apiEndpoint}/signin`, user);
}

export async function getDonatenow(jwt) {
    return await http.get(`${apiEndpoint}/new-donatenow`, {
      headers: { "admin-token": jwt },
    });
}