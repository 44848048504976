import React, { useState } from "react";
import TableFooter from "./TableFooter";
import useTable from "./hooks/useTable";

const AdminTable = ({ data, rowsPerPage }) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);
  return (
    <>
      <table style={{ marginTop: "0px" }}>
        <thead>
          <tr>
            <th>S/N</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Country</th>
            <th>Phone</th>
            <th>Currency</th>
            <th>Amount</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {slice.map((item, idx) => (
            <tr key={idx}>
              {console.log(item)}
              <td>{idx + 1}</td>
              <td>{item.firstName}</td>
              <td>{item.lastName}</td>
              <td>{item.email}</td>
              <td>{item.country}</td>
              <td>{item.phone}</td>
              <td>{item.currency}</td>
              <td>{item.amount}</td>
              <td>{item.createdAt}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </>
  );
};

export default AdminTable;
