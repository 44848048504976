import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DonationForm from './pages/donatenow';
import Footer from "./components/Footer";
import Header from "./components/Header";
import AdminLogin from './pages/admin/login/AdminLogin';
import AdminDashboard from './pages/admin/dashboard/AdminDashboard';

const App = () => {
  return (
    <>
        <Header />
      <main>
        <Routes>
          <Route exact path="/" element={<DonationForm />} />
          <Route exact path="/admin/login" element={<AdminLogin />} />
          <Route exact path="/admin/dashboard" element={<AdminDashboard/>}/>
        </Routes>
      </main>
        <Footer />
    </>
  );
}
 
export default App;