import React from 'react';
import "./spinner.css";

const Spinner = () => {
    return (
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    );
}
 
export default Spinner;