import React from "react";

function Input({ value, type, name, onChange, placeholder }) {
 
  return (
    <>
      <br />
      <input
        type={type}
        name={name}
        value={value}
        className={name}
        onChange={onChange}
        placeholder={placeholder}
      />
      <br />
    </>
  );
}

export default Input;
