import swal from "@sweetalert/with-react";
import React,{useState,useEffect} from "react";
import { getDonatenow } from "../../../services/adminService";
import AdminTable from "./Table";
import "./admin_dashboard.css";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";

const AdminDashboard = () => {
    const [data, setData] = useState([]);
    let navigate = useNavigate();
    useEffect(() => {
        const jwt = sessionStorage.getItem("admin-token");
        if(!jwt) return navigate("/admin/login")
        getDonatenow(jwt).then(res => {
            setData([...res.data.user])
            console.log(res)
        }).catch(err => {
            console.log(err);
            swal("Error", "Coudn't fetch data","error")
        })      
    },[])

    const headers = [
        { label: "Email", key: "email" },
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Country", key: "country" },
      { label: "Phone", key: "phone" },
      { label: "Amount", key: "amount" },
      { label: "Currency", key: "currency" },
      { label: "Date", key: "createdAt" },
    ];
 
function handleLogout(){
    sessionStorage.removeItem("admin-token")
    navigate("/admin/login");
}

    return (
      <>
        <div className="admin-dashboard">
          <h1>Admin Dashboard</h1>
          <button className="logout-btn" onClick={()=>handleLogout()}>Logout</button>

          <button className="download-btn">
            <CSVLink
              data={data}
              filename={"donatenow.csv"}
              target="_blank"
              headers={headers}
            >
              Download Data
            </CSVLink>
          </button>
          <br />
          {data.length === 0 ? (
            <span>No data to show</span>
          ) : (
            <>
              <br />
              <AdminTable data={data} rowsPerPage={10} />
            </>
          )}
        </div>
      </>
    );
}
 
export default AdminDashboard;